(function() {
  var gallery;

  gallery = function() {
    var $rotator, Rotate;
    $rotator = $("#rotator");
    $rotator.find("img:gt(0)").hide();
    return setTimeout(Rotate = (function() {
      var $current, $next;
      $current = $rotator.find("img:visible");
      $next = $current.next();
      if ($next.length === 0) {
        $next = $rotator.find("img:eq(0)");
      }
      $current.hide();
      $next.show();
      return setTimeout(Rotate, 5000);
    }), 1000);
  };

  $(document).ready(gallery);

  $(document).on('page:load', gallery);

}).call(this);