(function() {
  var header;

  header = function() {
    $('nav.header ul li a').mouseenter(function() {
      var class_string;
      $(this).addClass('hover');
      class_string = "<div class='star'></div>";
      return $(this).parent().append(class_string);
    });
    return $('nav.header ul li a').mouseleave(function() {
      $(this).removeClass('hover');
      return $(this).parent().find('.star').remove();
    });
  };

  $(document).ready(header);

  $(document).on('page:load', header);

}).call(this);